/* eslint-disable */
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
// assets
import { MENU_OPEN } from 'store/actions';
import config from 'config';
import { IconMenu2 } from '@tabler/icons-react';

import phhp from 'assets/images/phhp.png';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        {/* <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase> */}
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      {/* <ProfileSection /> */}
      <Stack direction="row" spacing={2}>
        <IconButton aria-label="delete" size="large" onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={config.basename}>
          <HomeIcon fontSize="inherit"/>
        </IconButton>
        <Button variant="outlined" size="large" component={Link} to={"/about"}>About</Button>
        <Button variant="outlined" size="large" component={Link} to={"/chemicals"}>Chemicals</Button>
        <Button variant="contained" size="large" component={Link} to={'https://docs.google.com/document/d/198jKwrZ3d3PM1wEA-gl4TDtm4Zt5P86Gsjl7xiWmQzg/edit?usp=sharing'}>Tutorial</Button>
      </Stack>

      <img src={phhp} alt="Data Preprocessing" style={{ width: "auto", height: 80, marginRight: 2, marginLeft:30 }}/>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
