/* eslint-disable */
import PropTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// initial state
const initialState = {
    test: 1
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ParametersContext = createContext(initialState);

function ParamProvider({ children }) {

    // const url = "http://localhost:8021";
    const url = "https://pfastkdatabase.phhp.ufl.edu/api";
    const [Catagory, setCatagory] = useState('species');

    const xLabelstemp = new Array(24).fill(0).map((_, i) => `${i}aaaaaaa`);
    const yLabels = ["PFAS", "PFNA", "PFHxS", "PFDA", "pfUnDA", "PFAS mix", "PFDoDA", "PFBS", "PFHpA", "PFHxA", "PFBA", "PFTrDA", "PFTeDA", "MeFOSAA", "PFHpS", "PFPeA", "EtFOSAA", "CI-PFESA", "GenX", "PFDS", "FTSA", "HFPO-TA", "ADONA", "PFO4DA", "PFO5DoDA", "PFPeS", "BP2", "PFNS", "Other"];
    const datatemp = new Array(yLabels.length)
    .fill(0)
    .map(() =>
        new Array(xLabelstemp.length).fill(0).map(() => Math.floor(Math.random() * 0))
    );

    const [data, setData] = useState({ xlabels: xLabelstemp, ylabels: yLabels, data: datatemp });

    const [a, setA] = useState("false");
    const [outputList, setOutputList] = useState([0]);

    const [frequencyGraph, setFrequencyGraph] = useState("chemicals")

    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);

    const [chemicalsFrequency, setChemicalsFrequency] = useState({});
    const [speciesFrequency, setSpeciesFrequency] = useState({});
    const [organsFrequency, setOrgansFrequency] = useState({});
    
    const [fetchError, setFetchError] = useState(null);

    useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/pfas/outputlist`);
    
                let outputList = [];
                for (let i = 0; i < response.data["records"].length; i++) {
                    outputList.push(response.data["records"][i]);
                }
                setOutputList(outputList);
                setFetchError(null);  // Reset fetch error on success
            } catch (error) {
                console.error('Error calling the API:', error);
                setFetchError(`Cannot fetch data: ${error.message}`);
            }
        }
        fetchData();
    },[Catagory])

    useEffect(() => {
        const fetchFrequencies = async () => {
            try {
                const response = await fetch(`${url}/pfas/frequency`);
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                const data = await response.json();
                setChemicalsFrequency(data.chemicals_frequency);
                setSpeciesFrequency(data.species_frequency);
                setOrgansFrequency(data.organs_frequency);
                setFetchError(null);  // Reset fetch error on success
            } catch (error) {
                console.error('Error fetching data:', error);
                setFetchError(`Cannot fetch data: ${error.message}`);
            }
        };

        fetchFrequencies();
    }, [url]);

    const CellClick = async (x, y) => {
        setX(x);
        setY(y);
        try {
            const response = await axios.post(`${url}/pfas/chemicals-species`, {
                substringChemical: data.ylabels[y],
                substringSpeci: Catagory === "species" ? data.xlabels[x] : "",
                substringOrgan: Catagory === "organs" ? data.xlabels[x] : ""
            });

            let outputList = [];
            for (let i = 0; i < response.data["records"].length; i++) {
                outputList.push(response.data["records"][i]);
            }
            setOutputList(outputList);
            setFetchError(null);  // Reset fetch error on success
        } catch (error) {
            console.error('Error calling the API:', error);
            setFetchError(`Cannot fetch data: ${error.message}`);
        }
    };

    const FreqClick = async (freqType, data) => {
        
        try {
            const response = await axios.post(`${url}/pfas/outputlist`, {
                freqType: freqType,
                dataString: data,
            });

            let outputList = [];
            for (let i = 0; i < response.data["records"].length; i++) {
                outputList.push(response.data["records"][i]);
            }
            setOutputList(outputList);
            setFetchError(null);  // Reset fetch error on success
        } catch (error) {
            console.error('Error calling the API:', error);
            setFetchError(`Cannot fetch data: ${error.message}`);
        }
    };

    const downloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8,"
            + outputList.map(record => 
                Object.values(record).map(value => 
                    typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
                ).join(",")
            ).join("\n");
    
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "outputList.csv");
        document.body.appendChild(link); // Required for FF
    
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/pfas/chemicals-species`);
                const { processed_chemicals, processed_species, processed_organs } = response.data;

                const uniqueChemicals = [...new Set(processed_chemicals.flat())];
                const uniqueSpecies = [...new Set(processed_species.flat())];
                const uniqueOrgans = [...new Set(processed_organs.flat())];

                let heatmapData = [];
                let xLabels = [];

                if (Catagory === 'species') {
                    heatmapData = uniqueChemicals.map(chemical => (
                        uniqueSpecies.map(species => (
                            processed_chemicals.reduce((count, chemicals, i) => (
                                count + (chemicals.includes(chemical) && processed_species[i].includes(species) ? 1 : 0)
                            ), 0)
                        ))
                    ));
                    xLabels = uniqueSpecies;
                } else if (Catagory === 'organs') {
                    heatmapData = uniqueChemicals.map(chemical => (
                        uniqueOrgans.map(organ => (
                            processed_chemicals.reduce((count, chemicals, i) => (
                                count + (chemicals.includes(chemical) && processed_organs[i].includes(organ) ? 1 : 0)
                            ), 0)
                        ))
                    ));
                    xLabels = uniqueOrgans;
                }

                const chemicalsDict = {};
                const xDict = {};
                uniqueChemicals.forEach((chemical, i) => {
                    chemicalsDict[chemical] = heatmapData[i];
                });
                xLabels.forEach((label, j) => {
                    xDict[label] = uniqueChemicals.map((_, i) => heatmapData[i][j]);
                });

                const chemicalsSum = Object.fromEntries(Object.entries(chemicalsDict).map(([k, v]) => [k, v.reduce((a, b) => a + b, 0)]));
                const xSum = Object.fromEntries(Object.entries(xDict).map(([k, v]) => [k, v.reduce((a, b) => a + b, 0)]));

                const sortedChemicals = Object.keys(chemicalsSum).sort((a, b) => chemicalsSum[b] - chemicalsSum[a]);
                const sortedX = Object.keys(xSum).sort((a, b) => xSum[b] - xSum[a]);

                const chemicalThreshold = 40;
                const filteredChemicals = sortedChemicals.filter(chemical => chemicalsSum[chemical] > chemicalThreshold);
                const xThreshold = Catagory === "organs" ? 80 : 20;
                const filteredX = sortedX.filter(label => xSum[label] > xThreshold);

                const filteredHeatmapData = filteredChemicals.map(chemical => (
                    filteredX.map(label => (
                        processed_chemicals.reduce((count, chemicals, i) => (
                            count + (chemicals.includes(chemical) && (
                                (Catagory === 'species' && processed_species[i].includes(label)) ||
                                (Catagory === 'organs' && processed_organs[i].includes(label))
                            ) ? 1 : 0)
                        ), 0)
                    ))
                ));

                setData({
                    xlabels: filteredX,
                    ylabels: filteredChemicals,
                    data: filteredHeatmapData,
                });
                setFetchError(null);  // Reset fetch error on success
            } catch (error) {
                console.error('Error fetching data:', error);
                setFetchError(`Cannot fetch data: ${error.message}`);
            }
        };

        fetchData();
        setOutputList([]);
    }, [Catagory, url]);

    return (
        <ParametersContext.Provider
            value={{
                a,
                X, setX,
                Y, setY,
                outputList,
                CellClick,
                data,
                Catagory,
                setCatagory,
                chemicalsFrequency,
                speciesFrequency,
                organsFrequency,
                fetchError,
                frequencyGraph,
                setFrequencyGraph,
                FreqClick,
                downloadCSV
            }}
        >
            {fetchError && <div style={{ color: 'red' }}>{fetchError}</div>}
            {children}
        </ParametersContext.Provider>
    );
}

export { ParamProvider, ParametersContext };
